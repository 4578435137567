:root {
    /* Colores principales */
    --background-color: #F4F4F4;
    --background-secondary-color: #ffffff;
    --color-primary: #000;
    --color-secondary: #0c65d2;
    --color-tertiary: #f2ac7a;
    --color-muted: #6f6f6f;
    --color-danger: #f6d6d6;
    --color-light: #d4d4d4;
    --color-dark: #000;
    
    /* Color de texto */
    --text-color: #0A2848;
    
    /* Variables para el modo oscuro */
    --animated-background-color: #efeeee;
    --animated-background-color-contrast: #e2e2e2;
  }
  
  /* Modo oscuro */
  [data-theme='dark'] {
    --background-color: #343434;
    --background-secondary-color: #262626;
    --text-color: #c2c2c2;
    --color-primary: #0a121d;
    --color-secondary: #506680;
    --color-muted: #2d2d2d;
    --color-danger: #A10000;
    --color-light: #565656;
    --color-dark: #d2d2d2;
    
    --animated-background-color: #3c3c3c;
    --animated-background-color-contrast: #444444;
  }