@font-face {
    font-family: "Avenir";
    src: url("../font/AvenirLTStd-Roman.otf");
}
@font-face {
    font-family: "Avenir ligth";
    src: url("../font/AvenirLTStd-Book.otf");
}


/*GENERAL Style*/
html,
body {
    position: relative;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    font-size: 12px;
    font-family: "Avenir";
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.container{
    @media (min-width: 1025px) {
        max-width: 1024px;
    }
}

svg {
    stroke: transparent;
    stroke-width: 0px;
}
section{
    min-height: 400px;
}

#root{
    height: 100%;
}

/* NAV BAR STYLE */
.nav  {
    padding: 20px
}
.nav .nav-link {
    padding: 3px 15px;
    font-size: 12px;
}

/* MENU STYLE */

/* FONT STYLE */
h2 {
    font-size: 48px;
    font-family: "Avenir";
    @media (max-width: 1300px) {
        font-size: 40px;
    }
    @media (max-width: 1200px) {
        font-size: 30px;
    }
    @media (max-width: 575px) {
        font-size: 38px;
    }
    @media (max-width: 770px) {
        font-size: 26px;
    }
}

p, a, button, .btn, .form-control, label {
    font-size: 16px;
    font-family: "Avenir ligth";
    @media (max-width: 1300px) {
        font-size: 14px;
    }
    @media (max-width: 980px) {
        font-size: 12px;
    }
}
a{
    text-decoration: none!important;
}

small {
    font-size: 12px;
}


.space-100{
    height: 100px;
}

.cursor-pointer{
    cursor: pointer;
}
.cursor-na{
    cursor: not-allowed!important;
}


.font-bold{
    font-weight: 900;
}
.font-semibold{
    font-weight: 600;
}
.font-light{
    font-weight: lighter!important;
}
.font-normal{
    font-weight: 300!important;
}


.text-white{
    color: var(--background-secondary-color) !important;
}
.text-primary{
    color: var(--color-primary) !important;
}
.text-secondary{
    color: var(--color-secondary) !important;
}
.text-dark{
    color: var(--text-color) !important;
}
.text-muted{
    color: var(--color-muted) !important;
}

.color-facebook{
    color: #1b74e4;
}
.color-strava{
    color: #fc5200;
}
.color-instagram{
    color: #833AB4;
}


.bg-white{
    background-color: var(--background-secondary-color);
}
.bg-primary{
    background: var(--color-primary) !important;
    background-color: var(--color-primary) !important;
}
.bg-secondary{
    background: var(--color-secondary)!important;
    background-color: var(--color-secondary) !important;
}
.bg-dark{
    background: var(--text-color) !important;
}
.bg-muted{
    background: var(--color-muted) !important;
}

.alert-warning {
    color: #585858;
    background-color: #E9D5AA;
    border-color: #E9D5AA;
}

.border-none{
    border: none!important;
}
.border-primary{
    border-color: var(--color-primary) !important;
}
.border-secondary{
    border-color: var(--color-secondary) !important;
}
.border-dark{
    border-color: var(--text-color) !important;
}
.border-white{
    border-color: var(--background-secondary-color) !important;
}
.border-muted{
    border-color: var(--color-muted) !important;
}

.btn{
    padding: 7.5px 30px;
    border-radius: 20px;
    border-color: transparent;
    
    &:hover, &:active {
        background: var(--color-dark);
        color: var(--background-secondary-color);
    }
}


.border-radius{
    border-radius: 20px;
}


.box-shadow{
    box-shadow: rgba(181, 181, 181 ,0.5) 1px 1px 3px 1px;
}
.box-shadow-right{
    box-shadow: -2px 10px 5px 5px rgba(181,181, 181,0.1);
}


.icon-sm{
    width: 20px;
}
.icon-xs{
    width: 7px;
}


.h-min-100{
    min-height: 100%;
}
.h-min-250px{
    min-height: 250px;
}
.w-max-300px{
    max-width: 300px;
}
.w-max-225px{
    max-width: 225px;
}


.position-relative{
    position: relative!important;
}
.position-absolute{
    position: absolute!important;
}
.position-fixed{
    position: fixed!important;
}


/* Img styles */
.img-fluid{
    max-width: 375px;
}


input[name="search"] {
    font-size: 12px;
}


/* Google tables custom */
.google-visualization-table-table th{
    background: #EEEEEE;
    border: none!important;
}
.google-visualization-table-table td{
    background: none!important;
    border:none!important
}
.google-visualization-table-tr-even {
    background-color: f7f7fa!important;
}



/*chatwoot scss*/ 
.woot-widget-bubble {
    border-radius: 0px!important;
    @media (max-width: 575px) {
        top: 25%;
        right: 0!important;
        max-width: 35px!important;
        min-height: 100px !important;
        border-top-right-radius: 0px!important;
        border-bottom-right-radius: 0px!important;
        img{
            height: 15px!important;
            width: 15px!important;
            margin: 0!important;
        }
    }
}





/* ---------------------------------------------------- */
/* FALTA DE DEPURAR ESTE CODE --------------------------*/
/* ---------------------------------------------------- */


#dashboardResult #tablaResult th{
    font-size: 12px!important;
}
#dashboardResult #tablaResult td{
    min-width: 100px;
    font-size: 12px!important;
}


#dashboardResult .google-visualization-controls-stringfilter label{
    position: absolute;
    z-index: 99;
    font-size: 10px;
    padding: 2px 5px;
    background: white;
    top: -10px;
    margin-left: 1px;
    color: #8b8787;
}
#dashboardResult .goog-inline-block{
    width: 100%;
}
#dashboardResult .google-visualization-controls-stringfilter{
    position: relative;
}
#dashboardResult .google-visualization-controls-stringfilter input{
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 0;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da!important;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 20px;;
}

#menu .active{
    background:#FFF5F6;
    color: #0A2848!important;
}

.c-header-realtive{
    position: relative!important;
}




.verticalSpace {
    height: 250px;
    @media (max-width: 1300px) {
        height: 0px;
    }
}

.icon {
    max-width: 60px;
    height: 60px;
    @media (max-width: 1200px) {
        max-width: 45px;
        height: 45px;
    }
}
.brand {
    width: 60px;
    max-height: 60px;
    @media (max-width: 1200px) {
        width: 40px;
        max-height: 40px;
    }
}
.ilustration{
    height: 120px;
    @media (max-width: 770px) {
        height: 80px;
    }
}

.card {
    min-height: 200px;

    .stars {
        position: absolute;
        bottom: 20px;
        .star {
            display: inline-grid;
            vertical-align: bottom;
            @media (max-width: 1200px) {
                width: 20px;
            }
        }
    }
}

.modal-content {
    border: none!important;
    width: 300px;
    height: 600px;
    margin: auto;
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}